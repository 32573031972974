import React from 'react'

import { graphql,
         useStaticQuery,
         Link }           from "gatsby"

import { normalize } from "../../hooks/normalize"
import { date      } from "../../hooks/upcc-date"

import arrow from "../../images/upcc/arrow.svg"

import Layout from "../share/upcc-layout";
import Footer from "../share/footer"
import { BlogContainer, NavContainer, MainContainer } from "./styles";
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { FacebookShareButton, WhatsappShareButton, TwitterShareButton, LinkedinShareButton, TelegramShareButton, TumblrShareButton, PinterestShareButton } from "react-share";
import { useLocation } from "@reach/router";
import CategoriesResponsive from "../arteycultura/CategoriesResponsive";

import Header from "../Header/upcc"

const Blog = ({ pageContext: { dataSingle } }) => {

  let { allContentfulArteYCultura: { nodes: data } } = useStaticQuery(
    graphql`
      query ($category: String){
        allContentfulArteYCultura(limit: 3, filter: {node_locale: {eq: "en-US"}, categorylink: {eq: $category}, activarPublicacion: {eq: true}}, sort: {fields: fechaDeOrden, order: DESC}) {
          nodes {
            titulo
            imagenDeLaEntrada { file { url } }
            tituloDeRespaldo
            descripcion
            descripcionEnElBlog
            fecha
            fechaDeOrden
          }
        },
      }
    `)

  console.log({ data })

  data.forEach(e => {
    e.titleURL    = e.titulo
    e.title       = e.tituloDeRespaldo
    e.subtitle    = e.descripcion
    e.image       = e.imagenDeLaEntrada
    e.description = e.descripcionEnElBlog
    e.date        = e.fecha
    e.path        = 'arteycultura'
  })

  const {
    descripcion,
    descripcionEnElBlog,
    imagenDeLaEntrada,
    lista,
    metaDescripcin,
    metaKeywords,
    metaTitle,
    titulo,
    tituloDeRespaldo,
    childContentfulArteYCulturaContenidoRichTextNode: { json }
  } = dataSingle;
  const { pathname } = useLocation()
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return <img src={`https:${node.data.target.fields.file["en-US"].url}`} alt="Imagen del blog" />
      },
      [INLINES.HYPERLINK]: (node) => {
        if ((node.data.uri).includes("<iframe")) {
          function createMarkup() { return { __html: `${node.data.uri}` }; };
          return <div className="rich-content-iframe" dangerouslySetInnerHTML={createMarkup()} />
        } else {
          return <a href={node.data.uri} target="_blank" rel="noopener noreferrer"><b>{node.content[0].value}</b></a>
        }
      },
    },
    renderMark: {},
  }

  return (
    <Layout
      title={tituloDeRespaldo || titulo}
      metaTitle={metaTitle}
      description={metaDescripcin}
      subtitile={metaDescripcin}
      metaDescripcin={metaDescripcin}
      imgurl={imagenDeLaEntrada.file.url}
      keywords={metaKeywords}
    >
      <Header/>

      <div class="upcc-article-wrapper">

        <section class="upcc-menu">
          <nav>
            <Link to={`/`}>Inicio</Link>
            &nbsp;/ <Link to={`/noticias`}>Noticias</Link>
          </nav>
        </section>

        <article class="upcc-article">

          <span class="upcc-tag">EDITORIAL</span>

          <div class="upcc-content">

            { documentToReactComponents(json, options) }

          </div> <br/><br/>

          <div class="separator"></div>

          <div class="upcc-credits">
            <b>"{ descripcionEnElBlog }"</b>
            <ul>
              { lista.map((text, i) => <li>{ text }</li>) }
            </ul>
          </div> <br/><br/>

          <div class="upcc-share">
            <span>Lo bueno, se comparte</span>
            <aside class="icons">
              <i class="fa fa-facebook"  onClick={() => document.getElementById('FacebookShareButton').click() }></i>
              <i class="fa fa-whatsapp"  onClick={() => document.getElementById('WhatsappShareButton').click() }></i>
              <i class="fa fa-twitter"   onClick={() => document.getElementById('TwitterShareButton').click()  }></i>
              <i class="fa fa-linkedin"  onClick={() => document.getElementById('LinkedinShareButton').click() }></i>
              <i class="fa fa-telegram"  onClick={() => document.getElementById('TelegramShareButton').click() }></i>
              <i class="fa fa-tumblr"    onClick={() => document.getElementById('TumblrShareButton').click()   }></i>
              <i class="fa fa-pinterest" onClick={() => document.getElementById('PinterestShareButton').click()}></i>
            </aside>

            <div className="d-none">
              <FacebookShareButton url={`https://cultural.upc.edu.pe${pathname}`} id="FacebookShareButton" />
              <WhatsappShareButton url={`https://cultural.upc.edu.pe${pathname}`} id="WhatsappShareButton" />
              <TwitterShareButton url={`https://cultural.upc.edu.pe${pathname}`} id="TwitterShareButton" />
              <LinkedinShareButton url={`https://cultural.upc.edu.pe${pathname}`} id="LinkedinShareButton" />
              <TelegramShareButton url={`https://cultural.upc.edu.pe${pathname}`} id="TelegramShareButton" />
              <TumblrShareButton url={`https://cultural.upc.edu.pe${pathname}`} id="TumblrShareButton" />
              <PinterestShareButton media={imagenDeLaEntrada.file.url} url={`https://cultural.upc.edu.pe/${pathname}`} id="PinterestShareButton" />
            </div>

          </div> <br/><br/>

        </article>


        <section class="upcc-controls">
          <div class="left">
            <span class="bar"></span>
            <h5 class="upcc-h5">También te puede gustar</h5>
          </div>
          <div class="right">
          </div>
        </section>

        <section class="upcc-cards two">
          {
            data.map(( post ) => {
              return (
                <article class="upcc-card">
                  <Link to={`/${post.path}/${normalize(post.titleURL)}`}>
                    <img class="upcc-card-img" src={ post.image.file.url } />
                  </Link>
                  <span class="upcc-tag upcc-card-tag">NOTICIAS</span>
                  <Link to={`/${post.path}/${normalize(post.titleURL)}`}>
                    <h5 class="upcc-h5 upcc-card-title">{ post.title } { post.subtitle }</h5>
                  </Link>
                  <p class="upcc-small upcc-card-desc">{ post.description }</p>
                  <footer>
                    <span class="upcc-small upcc-card-date">{ date(post.date).str }</span>
                    <Link className="upcc-card-more" to={`/${post.path}/${normalize(post.titleURL)}`}>
                      <p class="upcc-small underline">Leer más</p>
                      <img src={arrow} />
                    </Link>
                  </footer>
                </article>
              )
            })
          }
        </section>

        <section class="upcc-more">
          <Link className="upcc-button" to={`/noticias#more`}>Ver más</Link>
        </section>

      </div>

      <Footer />
    </Layout>
  )
}

export default Blog
